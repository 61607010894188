body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/*.only-print {*/
/*  display: none;*/
/*}*/

@media print {
  .no-print:not(.nothing),
  .no-print:not(.nothing) * {
    display: none !important;
  }

  /*.only-print,*/
  /*.do-print {*/
  /*  display: initial !important;*/
  /*}*/
}
